<template>
  <section class="row flexbox-container">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
        <div class="card border-grey border-lighten-3 m-0">
          <div class="card-header border-0">
            <div class="card-title text-center">
              <div class="p-1">Bagby Airfield Aircraft Monitoring</div>
            </div>
            <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2"><span>Login</span>
            </h6>
          </div>
          <div class="card-content">
            <div class="card-body">
              <form class="form-horizontal form-simple" id="login-form" @keyup.enter="login" novalidate>
                <fieldset class="form-group position-relative has-icon-left mb-0">
                  <input type="text" class="form-control" id="user-name" v-model="username" placeholder="Your Username" required>
                  <div class="form-control-position">
                    <i class="la la-user"></i>
                  </div>
                </fieldset>
                <fieldset class="form-group position-relative has-icon-left">
                  <input type="password" class="form-control" id="user-password" v-model="password" placeholder="Enter Password" required>
                  <div class="form-control-position">
                    <i class="la la-key"></i>
                  </div>
                </fieldset>
                <p class="card-text danger" id="login-error">{{ error }}</p>
                <button type="button" @click="login" class="btn btn-info btn-block"><i class="ft-unlock"></i> Login</button>
              </form>
            </div>
          </div>
          <div class="card-footer">
            Need an account?  <a href="mailto:peter@papa-bravo.aero">Contact us</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import AuthService from "@/services/AuthService";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      error: ""
    }
  },
  methods: {
    async login() {
      try {
        const user = await AuthService.authenticate(this.username, this.password);
        this.$emit('user-authenticated', user);
        this.error = "";
        await this.$router.push(this.$route.query.redirect || '/Home');
      } catch(e) {
        this.error = e.message;
      }
    }
  },
  emits: ['user-authenticated']
}
</script>